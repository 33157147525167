import { DialogBody, DialogClose } from "@/components/ui/dialog"
import React, { useEffect } from "react"
import { useModal } from "../../context/ModalContext"
import { useModalHelper } from "../../utils/useModalHelper"
import ModalButton from "../modal-button/ModalButton"
import ThruModal from "../thru-modal/ThruModal"

interface IDeleteModal {
  doDelete: () => any
  entityTypeName?: string
  entityName?: string
}

const DELETE_MODAL_ID = "delete-confirm"

const DeleteModal: React.FC<IDeleteModal> = ({ entityTypeName, doDelete, entityName }: IDeleteModal) => {
  const { closeModal } = useModal()
  const { openModal } = useModalHelper({
    modalId: DELETE_MODAL_ID,
    onHideModal: () => {
      closeModal()
    },
  })

  useEffect(() => {
    openModal()
  }, [])

  return (
    <>
      <ThruModal
        description={`The following ${entityTypeName} is being deleted.`}
        modalClass="delete-modal"
        centered
        id={DELETE_MODAL_ID}
        modalSize="lg"
        headerVariant={"destructive"}
        title={`Delete ${entityTypeName}`}
        buttons={[
          <ModalButton
            id="delete-modal-confirm"
            variant="destructive"
            key={0}
            onClick={() => {
              setTimeout(async () => {
                try {
                  await doDelete()
                } catch (e) {}
                closeModal()
              }, 100)
            }}
          >
            Delete
          </ModalButton>,
          <DialogClose asChild key={1}>
            <ModalButton
              id="close-delete-modal"
              variant="outline_neutral"
              onClick={() => {
                closeModal()
              }}
            >
              Cancel
            </ModalButton>
          </DialogClose>,
        ]}
      >
        <DialogBody>
          Once you delete this {entityTypeName}, it cannot be restored.
          <br />
          <br />
          Are you sure you want to delete {entityTypeName} <b>{entityName}</b>?
        </DialogBody>
      </ThruModal>
    </>
  )
}

export default DeleteModal
