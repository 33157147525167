import { useAuthStore } from "@/context/AuthContext"
import React from "react"

interface IPasswordStrengthMeter {
  password: string
  mfaEnabled?: boolean
}

const PasswordStrengthMeter: React.FC<IPasswordStrengthMeter> = ({ password, mfaEnabled }: IPasswordStrengthMeter) => {
  const trustedDeviceExpires = useAuthStore((state) =>
    !!state.currentUser?.TrustedDeviceExpires ? new Date(state.currentUser?.TrustedDeviceExpires) : undefined,
  )
  const customerName = useAuthStore((state) => state.currentUser?.CustomerName)
  const username = useAuthStore((state) => state.currentUser?.UserName)

  const mfaPresent = mfaEnabled === undefined ? !!trustedDeviceExpires : mfaEnabled
  const passwordLength = password?.length ?? 0
  const lengthFactor = Math.min(1.0, passwordLength / 8.0)
  const factors = [lengthFactor]
  const requirements = [
    passwordLength < (mfaPresent ? 8 : 12)
      ? { message: `Your password must be atleast ${mfaPresent ? 8 : 12} characters.`, severe: false }
      : undefined,
  ].filter((requirement) => !!requirement)
  const strength = Math.min(
    factors.reduce((prev, curr) => prev + curr / factors.length, 0) * 100,
    requirements.length === 0 ? 100 : 79,
  )
  let color = strength >= 80 ? "#00B74A" : strength >= 60 ? "#FFF176" : strength >= 40 ? "#FFA900" : "#F93154"
  if (requirements.length !== 0 && requirements.some((req) => req?.severe)) {
    color = "#F93154"
  }

  return (
    <div style={{ opacity: passwordLength === 0 ? "0.0" : "1.0", transition: "opacity 400ms ease" }}>
      <div key={0} style={{ backgroundColor: "lightgray", borderRadius: "4px" }}>
        <div
          style={{
            backgroundColor: color,
            width: `${strength.toFixed(2)}%`,
            height: "6px",
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: strength >= 100 ? "4px" : "0px",
            borderTopRightRadius: strength >= 100 ? "4px" : "0px",
            marginTop: "4px",
            transition: "all 250ms ease",
          }}
        ></div>
      </div>
      <small
        className="form-text"
        style={{
          color: requirements.length !== 0 ? "#e9666f" : "#00B74A",
          opacity: passwordLength !== 0 ? "1.0" : "0.0",
          transition: "opacity 400ms ease, color 400ms ease",
        }}
        id="password-validation-status"
      >
        {requirements?.[0]?.message ?? "Strong password"}
      </small>
    </div>
  )
}

export default PasswordStrengthMeter
