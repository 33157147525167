import { useCallback, useState } from "react"
import { v4 } from "uuid"
import { create } from "zustand"

interface DownloadStore {
  loadingComponents: Set<string>
  setLoading: (key: string, value: boolean) => void
}

export const useLoaderStore = create<DownloadStore>()((set, get) => ({
  loadingComponents: new Set(),
  setLoading: (key: string, value: boolean) => {
    set((state) => {
      if (value) {
        state.loadingComponents.add(key)
      } else {
        state.loadingComponents.delete(key)
      }

      return { loadingComponents: state.loadingComponents }
    })
  },
}))

export const useSetLoading = () => {
  const [loaderUUID, _] = useState(v4())

  const setLoading = useLoaderStore((state) => state.setLoading)
  const setLoadingUUID = useCallback((loading: boolean) => setLoading(loaderUUID, loading), [loaderUUID])

  return setLoadingUUID
}
