import { DialogClose } from "@/components/ui/dialog"
import React from "react"
import { useModal } from "../../context/ModalContext"
import { useModalHelper } from "../../utils/useModalHelper"
import ModalButton from "../modal-button/ModalButton"
import { useModalPrompt } from "../modal-prompt/useModalPrompt"
import ThruModal from "../thru-modal/ThruModal"

interface IDiscardModal {
  discardConfirm: () => Promise<void>
}

export const MODAL_ID = "discard-confirm"

const DiscardModal: React.FC<IDiscardModal> = ({ discardConfirm }: IDiscardModal) => {
  const popPrompt = useModalPrompt((state) => state.popPrompt)
  // removeModal will remove the modal from the DOM
  const { closeModal: removeModal } = useModal()
  const { closeModal } = useModalHelper({
    modalId: MODAL_ID,
    onHideModal: () => {
      removeModal()
      setTimeout(() => {
        popPrompt()
      }, 300)
    },
  })

  return (
    <>
      <ThruModal
        id="discard-confirm"
        description="The following changes will be discarded."
        title="Discard Changes"
        headerVariant="destructive"
        defaultOpen={true}
        modalSize="lg"
        centered
        buttons={[
          <ModalButton
            key={0}
            id="submit-discard"
            variant="destructive"
            onClick={() => {
              setTimeout(async () => {
                await discardConfirm()
                closeModal()
              }, 100)
            }}
          >
            Discard
          </ModalButton>,
          <DialogClose key={1} asChild>
            <ModalButton
              id="close-discard-modal"
              variant="outline_neutral"
              onClick={() => {
                closeModal()
              }}
            >
              Cancel
            </ModalButton>
          </DialogClose>,
        ]}
      >
        <div className="p-6 text-center">
          You have made changes to this item. <br />
          Do you want to discard these changes?
        </div>
      </ThruModal>
    </>
  )
}

export default DiscardModal
