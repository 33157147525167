import axios, { AxiosRequestConfig } from "axios"
import { useAuthStore } from "../context/AuthContext"
import { apiUrl } from "./config"

export async function baseClient<T>(
  endpoint: any,
  {
    body,
    method,
    dataOnly = true,
    onDownloadProgress,
    onUploadProgress,
    responseType,
    cancelTokenSource,
    validateStatus,
    signal,
    urlOverride = undefined,
    headers = {},
    ...customConfig
  }: any = {},
) {
  const url = urlOverride ?? `${apiUrl}${endpoint}`

  const config: AxiosRequestConfig = {
    cancelToken: cancelTokenSource?.token,
    signal: signal,
    params: { ...customConfig },
    headers: headers,
    onDownloadProgress: onDownloadProgress,
    onUploadProgress: onUploadProgress,
  }

  if (!!validateStatus) {
    config.validateStatus = validateStatus
  }

  const accessToken = useAuthStore.getState().currentUser?.accessToken

  if (accessToken) {
    config.headers = { Authorization: `Bearer ${accessToken}`, ...headers }
  }

  let res
  if (method === "PUT") {
    res = await axios.put<T>(url, body, config)
  } else if (method === "DELETE") {
    res = await axios.delete<T>(url, config)
  } else if (method === "POST" || body) {
    res = await axios.post<T>(url, body, {
      ...config,
      responseType: responseType,
    })
  } else {
    res = await axios({
      url: url,
      responseType: responseType,
      headers: { Authorization: !!accessToken ? `Bearer ${accessToken}` : undefined, ...headers },
      onDownloadProgress: onDownloadProgress,
      params: { ...customConfig },
    })
  }

  if (dataOnly) {
    return res?.data
  }
  return res
}

async function client<T>(endpoint: any, { ...config }: any = {}) {
  return baseClient(endpoint, { ...config }) as Promise<T>
}

export default client
