import { Button } from "@/components/ui/button"
import { Input, InputProps } from "@/components/ui/input"
import { useViewPermission } from "@/context/AuthContext"
import { faEye, faEyeSlash } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { useFormContext, useFormState, ValidateResult } from "react-hook-form"
import { useThruFormContext } from "../../context/ThruFormContext"
import SkeletonFormControl from "../skeleton-form-control/SkeletonFormControl"

export interface IPasswordInput extends InputProps {
  name: string
  defaultValue?: string
  placeholder?: string
  minLength?: number
  maxLength?: number
  readOnly?: boolean
  required?: boolean
  autoComplete?: string
  newPassword?: boolean
  buttonIdPrefix?: string
  validate?: (data: any) => ValidateResult | Promise<ValidateResult>
}

const PasswordInput: React.FC<IPasswordInput> = ({
  name,
  defaultValue,
  minLength,
  maxLength,
  placeholder,
  readOnly,
  validate,
  autoComplete,
  newPassword = true,
  buttonIdPrefix,
  required = false,
  ...rest
}: IPasswordInput) => {
  const { loading, idPrefix, requiredPermissionsForWrite } = useThruFormContext()
  const { register } = useFormContext()
  const { errors } = useFormState({ name: name })

  const viewAllowed = useViewPermission(requiredPermissionsForWrite)

  const id = `${idPrefix ?? ""}${name}`
  const error = errors[name]

  const props = {
    ...rest,
    id: id,
    ...register(name, {
      required: { value: required, message: "Please supply a value." },
      minLength: minLength,
      maxLength: maxLength,
      validate: validate,
    }),
    type: "text",
    defaultValue: defaultValue,
    className: `form-control${errors[name] ? " validationError" : ""}`,
    minLength: minLength,
    maxLength: maxLength ?? 256,
    placeholder: placeholder,
    readOnly: viewAllowed ? readOnly : true,
    autoComplete: autoComplete,
    "data-toggle": "tooltip",
  }

  const [obfuscatePassword, setObfuscatePassword] = useState<boolean>(true)

  const buttonId = [buttonIdPrefix, id, "password-show"].filter(Boolean).join("-")

  return (
    <SkeletonFormControl loading={loading ?? false} inline>
      <div className="input-group relative">
        <Input
          {...props}
          className="pr-10"
          type={obfuscatePassword ? "password" : "text"}
          autoComplete={newPassword ? "new-password" : undefined}
          data-lpignore={true}
        />
        <div className="input-group-append">
          <Button
            type="button"
            variant="ghost"
            size="sm"
            className="absolute right-0 top-0 h-full px-3 py-2"
            onClick={() => setObfuscatePassword((prev) => !prev)}
            tabIndex={(rest.tabIndex ?? -1) + 1}
          >
            {!obfuscatePassword ? (
              <FontAwesomeIcon icon={faEye} swapOpacity fixedWidth />
            ) : (
              <FontAwesomeIcon icon={faEyeSlash} swapOpacity fixedWidth />
            )}
            <span className="sr-only">{obfuscatePassword ? "Hide password" : "Show password"}</span>
          </Button>
        </div>
      </div>
      {error?.message &&
        error.message
          .toString()
          .split("\n")
          .map((msg: string, i: number) => (
            <small key={i} className="form-text danger-color">
              {msg}
            </small>
          ))}
    </SkeletonFormControl>
  )
}

export default PasswordInput
