import { useLayoutEffect } from "react"

function useCloseModal(modalName: string) {
  useLayoutEffect(() => {
    return () => {
      document.getElementById(`${modalName}-close`)?.click()
    }
  }, [modalName])
}

export { useCloseModal }
