import { z } from "zod"

const bytesToBase64 = (bytes: any) => {
  const binString = String.fromCodePoint(...bytes)
  return btoa(binString)
}

export const encodeBase64 = (s: string) => {
  return bytesToBase64(new TextEncoder().encode(s))
}

export const decodeBase64 = (s: string) => {
  var base64 = s.replace(/-/g, "+").replace(/_/g, "/")
  var payload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(""),
  )
  return payload
}

// Create zod type
export const jwtInfoModelSchema = z.object({
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": z.string(),
  UserId: z.string().transform((val) => parseInt(val)),
  UserName: z.string(),
  FirstName: z.string(),
  LastName: z.string(),
  TenantId: z.string().transform((val) => parseInt(val)),
  ctx: z.string().transform((val) => parseInt(val)),
  CustomerName: z.string(),
  CustomerSystemCode: z.string(),
  OrgIds: z.string().transform((val) => val.split(",").map(Number).filter(Boolean)),
  PermissionCodes: z.string().transform((val) => val.split(",").map(Number).filter(Boolean)),
  TrustedDeviceExpires: z
    .string()
    .transform((val) => parseInt(val))
    .optional(),
  exp: z.number(),
  iss: z.string(),
  aud: z.string(),
  DefaultUploadFlowEndpointId: z.string().optional(),
})
export type JwtInfoModel = z.infer<typeof jwtInfoModelSchema>

export const parseJwt = (token: string) => {
  var base64Url = token.split(".")[1]
  var jsonPayload = decodeBase64(base64Url)

  return jwtInfoModelSchema.parse(JSON.parse(jsonPayload))
}

const PERMISSION_CODE = {
  REPORTING_APIS: 1000,

  FILE_ACTIVITY: 1200,

  REPLAY_FILES_FROM_ACTIVITY: 1201,

  DOWNLOAD_FILE_FROM_ACTIVITY: 1202,

  DELETE_FILE_FROM_ACTIVITY: 1203,

  BILLING: 1300,

  SEARCH: 1400,

  ALERTS_GET_LIST: 2000,
  ALERTS_UPDATE_STATUS: 2002,

  FILES_UPLOAD: 3100,

  FILES_DOWNLOAD: 3200,

  ORGANIZATION_GET_LIST: 4000,
  ORGANIZATION_CREATE: 4001,
  ORGANIZATION_UPDATE: 4002,
  ORGANIZATION_DELETE: 4003,

  ORGANIZATION_ENDPOINTS_GET_LIST: 4100,
  ORGANIZATION_ENDPOINTS_CREATE: 4101,
  ORGANIZATION_ENDPOINTS_UPDATE: 4102,
  ORGANIZATION_ENDPOINTS_DELETE: 4103,

  ORGANIZATION_SUBSCRIPTION_GET_LIST: 4200,
  ORGANIZATION_SUBSCRIPTION_CREATE: 4201,
  ORGANIZATION_SUBSCRIPTION_UPDATE: 4202,
  ORGANIZATION_SUBSCRIPTION_DELETE: 4203,

  ORGANIZATION_PGP_KEYS_GET_LIST: 4300,
  ORGANIZATION_PGP_KEYS_CREATE: 4301,
  ORGANIZATION_PGP_KEYS_UPDATE: 4302,
  ORGANIZATION_PGP_KEYS_DELETE: 4303,

  ORGANIZATION_SSH_KEYS_GET_LIST: 4400,
  ORGANIZATION_SSH_KEYS_CREATE: 4401,
  ORGANIZATION_SSH_KEYS_UPDATE: 4402,
  ORGANIZATION_SSH_KEYS_DELETE: 4403,

  ORGANIZATION_CERTIFICATES_GET_LIST: 4500,
  ORGANIZATION_CERTIFICATES_CREATE: 4501,
  ORGANIZATION_CERTIFICATES_UPDATE: 4502,
  ORGANIZATION_CERTIFICATES_DELETE: 4503,

  ORGANIZATION_THRU_NODES_GET_LIST: 4600,
  ORGANIZATION_THRU_NODES_CREATE: 4601,
  ORGANIZATION_THRU_NODES_UPDATE: 4602,
  ORGANIZATION_THRU_NODES_DELETE: 4603,

  ORGANIZATION_MACHINE_USERS_GET_LIST: 4700,
  ORGANIZATION_MACHINE_USERS_CREATE: 4701,
  ORGANIZATION_MACHINE_USERS_UPDATE: 4702,
  ORGANIZATION_MACHINE_USERS_DELETE: 4703,

  FLOWS_GET_LIST: 5000,
  FLOWS_CREATE: 5001,
  FLOWS_UPDATE: 5002,
  FLOWS_DELETE: 5003,
  FLOW_GET_FILEZILLA_CONFIG: 5010,
  FLOW_EXPORT: 5011,
  FLOW_IMPORT: 5012,
  FLOW_LIST_TRANSFER_REQUESTS: 5020,
  FLOW_LIST_CLIENT_RESPONSES: 5021,
  FLOW_GET_ACTIVE_SCHEDULES: 5022,

  FLOW_ENDPOINTS_GET_LIST: 5100,
  FLOW_ENDPOINTS_CREATE: 5101,
  FLOW_ENDPOINTS_UPDATE: 5102,
  FLOW_ENDPOINTS_DELETE: 5103,
  FLOW_ENDPOINTS_RUN: 5104,

  FLOW_SUBSCRIPTION_GET_LIST: 5200,
  FLOW_SUBSCRIPTION_CREATE: 5201,
  FLOW_SUBSCRIPTION_DELETE: 5203,

  FLOW_HISTORY_GET_LIST: 5300,
  FLOW_HISTORY_ROLLBACK: 5301,

  FLOW_ENDPOINT_ALERTS_GET_LIST: 5410,
  FLOW_ENDPOINT_ALERTS_CREATE: 5411,
  FLOW_ENDPOINT_ALERTS_UPDATE: 5412,
  FLOW_ENDPOINT_ALERTS_DELETE: 5413,

  FLOW_ENDPOINT_ANTIVIRUS_GET_LIST: 5420,
  FLOW_ENDPOINT_ANTIVIRUS_CREATE: 5421,
  FLOW_ENDPOINT_ANTIVIRUS_UPDATE: 5422,
  FLOW_ENDPOINT_ANTIVIRUS_DELETE: 5423,

  FLOW_ENDPOINT_COMPRESSION_GET_LIST: 5430,
  FLOW_ENDPOINT_COMPRESSION_CREATE: 5431,
  FLOW_ENDPOINT_COMPRESSION_UPDATE: 5432,
  FLOW_ENDPOINT_COMPRESSION_DELETE: 5433,

  FLOW_ENDPOINT_CONFIGURATIONS_GET_LIST: 5440,
  FLOW_ENDPOINT_CONFIGURATIONS_CREATE: 5441,
  FLOW_ENDPOINT_CONFIGURATIONS_UPDATE: 5442,
  FLOW_ENDPOINT_CONFIGURATIONS_DELETE: 5443,

  FLOW_ENDPOINT_EMAIL_NOTIFICATIONS_GET_LIST: 5450,
  FLOW_ENDPOINT_EMAIL_NOTIFICATIONS_CREATE: 5451,
  FLOW_ENDPOINT_EMAIL_NOTIFICATIONS_UPDATE: 5452,
  FLOW_ENDPOINT_EMAIL_NOTIFICATIONS_DELETE: 5453,

  FLOW_ENDPOINT_ENCRYPTION_GET_LIST: 5460,
  FLOW_ENDPOINT_ENCRYPTION_CREATE: 5461,
  FLOW_ENDPOINT_ENCRYPTION_UPDATE: 5462,
  FLOW_ENDPOINT_ENCRYPTION_DELETE: 5463,

  FLOW_ENDPOINT_PROCESSING_GET_LIST: 5470,
  FLOW_ENDPOINT_PROCESSING_CREATE: 5471,
  FLOW_ENDPOINT_PROCESSING_UPDATE: 5472,
  FLOW_ENDPOINT_PROCESSING_DELETE: 5473,

  FLOW_ENDPOINT_SCHEDULES_GET_LIST: 5480,
  FLOW_ENDPOINT_SCHEDULES_CREATE: 5481,
  FLOW_ENDPOINT_SCHEDULES_UPDATE: 5482,
  FLOW_ENDPOINT_SCHEDULES_DELETE: 5483,

  FLOW_ENDPOINT_RENAME_OPTIONS_GET_LIST: 5490,
  FLOW_ENDPOINT_RENAME_OPTIONS_CREATE: 5491,
  FLOW_ENDPOINT_RENAME_OPTIONS_UPDATE: 5492,
  FLOW_ENDPOINT_RENAME_OPTIONS_DELETE: 5493,

  FLOW_ENDPOINT_SERVER_PATH_MAPPINGS_GET_LIST: 5500,
  FLOW_ENDPOINT_SERVER_PATH_MAPPINGS_CREATE: 5501,
  FLOW_ENDPOINT_SERVER_PATH_MAPPINGS_UPDATE: 5502,
  FLOW_ENDPOINT_SERVER_PATH_MAPPINGS_DELETE: 5503,

  FLOW_ENDPOINT_SERVER_PATHS_GET_LIST: 5510,
  FLOW_ENDPOINT_SERVER_PATHS_CREATE: 5511,
  FLOW_ENDPOINT_SERVER_PATHS_UPDATE: 5512,
  FLOW_ENDPOINT_SERVER_PATHS_DELETE: 5513,

  FLOW_ENDPOINT_SERVER_USERS_GET_LIST: 5520,
  FLOW_ENDPOINT_SERVER_USERS_CREATE: 5521,
  FLOW_ENDPOINT_SERVER_USERS_UPDATE: 5522,
  FLOW_ENDPOINT_SERVER_USERS_DELETE: 5523,

  FLOW_ENDPOINT_SERVER_USER_FTP_AUTH_PASSWORD: 5524,
  FLOW_ENDPOINT_SERVER_USER_FTP_AUTH_CERTIFICATE: 5525,
  FLOW_ENDPOINT_SERVER_USER_FTP_AUTH_CERTIFICATE_PASSWORD: 5526,
  FLOW_ENDPOINT_SERVER_USER_SFTP_AUTH_PASSWORD: 5527,
  FLOW_ENDPOINT_SERVER_USER_SFTP_AUTH_SSH_KEY: 5528,

  FLOW_ENDPOINT_TRIGGERS_GET_LIST: 5530,
  FLOW_ENDPOINT_TRIGGERS_CREATE: 5531,
  FLOW_ENDPOINT_TRIGGERS_UPDATE: 5532,
  FLOW_ENDPOINT_TRIGGERS_DELETE: 5533,

  FLOW_ENDPOINT_CONNECTIONS_GET_LIST: 5540,
  FLOW_ENDPOINT_CONNECTIONS_CREATE: 5541,
  FLOW_ENDPOINT_CONNECTIONS_UPDATE: 5542,
  FLOW_ENDPOINT_CONNECTIONS_DELETE: 5543,

  USER_MANAGEMENT_GET_USERS: 6000,
  USER_MANAGEMENT_CREATE_USER: 6001,
  USER_MANAGEMENT_UPDATE_USER: 6002,
  USER_MANAGEMENT_DELETE_USER: 6003,
  USER_MANAGEMENT_UNBAN_USER: 6004,
  USER_MANAGEMENT_RESEND_ACTIVATION_EMAIL: 6005,

  INSTANCE_ADMIN_APIS: 6011,

  ADMIN_THRU_NODES_GET: 6020,

  ADMIN_FEATURE_FLAG_GET: 6100,
  ADMIN_FEATURE_FLAG_CREATE: 6101,
  ADMIN_FEATURE_FLAG_UPDATE: 6102,
  ADMIN_FEATURE_FLAG_DELETE: 6103,

  ROLES_GET: 6200,
  ROLES_CREATE: 6201,
  ROLES_UPDATE: 6202,
  ROLES_DELETE: 6203,
  ROLES_ASSIGN_ROLE_TO_USER_GROUP: 6204,

  API_KEY_GET: 6300,
  API_KEY_CREATE: 6301,
  API_KEY_UPDATE: 6302,
  API_KEY_DELETE: 6303,

  RETENTION_GET: 6400,
  RETENTION_CREATE: 6401,
  RETENTION_UPDATE: 6402,
  MFA_GET: 6410,
  MFA_UPDATE: 6412,

  CUSTOMER_GET: 8000,
  CUSTOMER_CREATE: 8001,
  CUSTOMER_UPDATE: 8002,
  CUSTOMER_DELETE: 8003,

  THRU_OPS_APIS: 9000,

  BASIC_AUTH_API: 10011,
  SSO_AUTH_API: 10021,
  USER_PASSWORD_APIS: 11001,
}

export { PERMISSION_CODE }
