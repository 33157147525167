import { siteSettingsActionTypes } from "../utils/actionTypes"

const initialState = {
  siteSettings: undefined,
  loading: false,
  error: null,
}

function siteSettingsReducer(state = initialState, { type, payload }: any) {
  switch (type) {
    case siteSettingsActionTypes.SEARCH_SITE_SETTINGS: {
      return {
        ...state,
        loading: true,
        error: null,
        pgpOptions: undefined,
      }
    }
    case siteSettingsActionTypes.RECEIVE_SITE_SETTINGS: {
      return {
        ...state,
        loading: false,
        error: null,
        siteSettings: payload,
      }
    }
    case siteSettingsActionTypes.SITE_SETIINGS_ERROR: {
      return {
        ...state,
        loading: false,
        siteSettings: {},
        error: payload,
      }
    }
    default: {
      throw new Error(`The type ${type} is not a known type...`)
    }
  }
}

export { initialState, siteSettingsReducer }
