import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  modalHeaderVariants,
  modalPlacementVariants,
  modalSizeVariants,
} from "@/components/ui/dialog"
import { cn } from "@/lib/utils"
import { VariantProps } from "class-variance-authority"
import React, { ReactNode, useState } from "react"
import * as portals from "react-reverse-portal"
import { useModalPortal } from "../../context/ModalPortalContext"
import { useCloseModal } from "../../utils/useCloseModal"

interface IThruModal extends React.ComponentPropsWithoutRef<typeof AlertDialog> {
  id: string
  title: ReactNode
  description: string
  buttons?: ReactNode[]
  headerVariant?: VariantProps<typeof modalHeaderVariants>["variant"]
  modalClass?: string
  modalContentClass?: string
  modalBodyProps?: any
  modalSize?: VariantProps<typeof modalSizeVariants>["size"]
  modalAlignment?: VariantProps<typeof modalPlacementVariants>["align"]
  modalFooter?: ReactNode
  children: ReactNode
  scrollable?: boolean
  centered?: boolean
  staticModal?: boolean
  onClose?: () => boolean
}

const ThruModal: React.FC<IThruModal> = ({
  id,
  title,
  buttons,
  modalClass = "",
  modalContentClass = "",
  modalBodyProps,
  modalSize = "default",
  modalAlignment,
  modalFooter,
  headerVariant,
  children,
  scrollable,
  centered,
  staticModal,
  onClose,
  description,
  ...rest
}: IThruModal) => {
  useCloseModal(id)
  const { portalNode } = useModalPortal()
  const [open, setOpen] = useState(rest.defaultOpen ?? false)

  const handleOpenChange = (open: boolean) => {
    if (open) {
      const event = new CustomEvent("shown.bs.modal", {
        bubbles: true,
        cancelable: true,
        detail: {
          id: id,
        },
      })
      document?.dispatchEvent(event)
      setOpen(true)
    } else {
      if (onClose?.() !== false || onClose === undefined) {
        setOpen(false)
        const event = new CustomEvent("hide.bs.modal", {
          bubbles: true,
          cancelable: true,
          detail: {
            id: id,
          },
        })
        document?.dispatchEvent(event)
      }
    }
  }

  if (staticModal) {
    return (
      <portals.InPortal node={portalNode}>
        <AlertDialog defaultOpen={rest.defaultOpen ?? false} open={open} onOpenChange={handleOpenChange} {...rest}>
          <AlertDialogTrigger asChild>
            <Button className="hidden" id={`${id}-open`}>
              Open
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent
            {...modalBodyProps}
            size={modalSize}
            align={modalAlignment}
            id={id}
            className={cn(modalClass, modalBodyProps?.className)}
          >
            <AlertDialogHeader variant={headerVariant}>
              <AlertDialogTitle>{title}</AlertDialogTitle>
            </AlertDialogHeader>
            {description && <AlertDialogDescription className="hidden">{description}</AlertDialogDescription>}
            {children}
            {(buttons?.length ?? 0) > 0 && (
              <AlertDialogFooter>
                {React.Children.map(buttons, (button, index) => {
                  return React.cloneElement(button as React.ReactElement, {
                    key: index,
                  })
                })}
              </AlertDialogFooter>
            )}
            {modalFooter}
          </AlertDialogContent>
        </AlertDialog>
      </portals.InPortal>
    )
  }

  return (
    <portals.InPortal node={portalNode}>
      <Dialog defaultOpen={rest.defaultOpen ?? false} open={open} onOpenChange={handleOpenChange} {...rest}>
        <DialogTrigger asChild>
          <Button className="hidden" id={`${id}-open`}>
            Open
          </Button>
        </DialogTrigger>
        <DialogClose asChild>
          <Button className="hidden" id={`${id}-close`}>
            Close
          </Button>
        </DialogClose>
        <DialogContent
          {...modalBodyProps}
          size={modalSize}
          align={modalAlignment}
          id={id}
          className={cn(modalClass, modalBodyProps?.className)}
        >
          <DialogHeader variant={headerVariant}>
            <DialogTitle>{title}</DialogTitle>
          </DialogHeader>
          {description && <DialogDescription className="hidden">{description}</DialogDescription>}
          {children}
          {(buttons?.length ?? 0) > 0 && (
            <DialogFooter>
              {React.Children.map(buttons, (button, index) => {
                return React.cloneElement(button as React.ReactElement, {
                  key: index,
                })
              })}
            </DialogFooter>
          )}
        </DialogContent>
      </Dialog>
    </portals.InPortal>
  )
}

export default ThruModal
