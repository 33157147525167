import * as SamlLoginClient from "../utils/SamlLoginClient"

import React, { useEffect } from "react"
import { initialState, siteSettingsReducer } from "../reducers/SiteSettingsReducer"
import { siteSettingsActionTypes } from "../utils/actionTypes"
import { constructError } from "../utils/ErrorUtils"

const SiteSettingsContext = React.createContext()

function SiteSettingsProvider({ children }) {
  const [state, dispatch] = React.useReducer(siteSettingsReducer, initialState)

  async function getSiteOptions() {
    try {
      if (state.siteSettings || state.loading) {
        return
      }

      dispatch({ type: siteSettingsActionTypes.SEARCH_SITE_SETTINGS })

      const response = await SamlLoginClient.siteSetting().client()
      if (response.status === 200) {
        dispatch({
          type: siteSettingsActionTypes.RECEIVE_SITE_SETTINGS,
          payload: response,
        })
      } else {
        dispatch({
          type: siteSettingsActionTypes.RECEIVE_SITE_SETTINGS,
          payload: {},
        })
      }
    } catch (e) {
      const payload = constructError(e)
      dispatch({
        type: siteSettingsActionTypes.SITE_SETIINGS_ERROR,
        payload,
      })
    }
  }

  return <SiteSettingsContext.Provider value={{ state, getSiteOptions }}>{children}</SiteSettingsContext.Provider>
}

function useSiteSettingOptions() {
  const context = React.useContext(SiteSettingsContext)
  if (context === undefined) {
    throw new Error("usePgpOptions must be used within a SiteSettingsProvider")
  }

  useEffect(() => {
    context.getSiteOptions()
  }, [context])

  return context
}

export { SiteSettingsProvider, useSiteSettingOptions }
