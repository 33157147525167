import { signupConstants } from "./constants"

import { AxiosResponse } from "axios"
import { z } from "zod"
import { LoginCredentials } from "../context/AuthContext"
import vanillaClient from "../utils/AxiosClient"
import { parseJwt } from "./PermissionCodes"

function create(endpoint: any, params: any) {
  return vanillaClient(endpoint, params)
}

function validateUserInfo({ password, role }: any) {
  const validPassword = password && password.length > 0
  let type = undefined

  if (!validPassword) {
    type = signupConstants.INVALID_PASSWORD
  }

  return type
}

function signup(userInfo: any) {
  const type = validateUserInfo(userInfo)

  if (type) {
    return Promise.reject({
      error: true,
      message: signupConstants.BAD_USER_INFO,
      type,
    })
  }

  return create("PortalUsers", { body: userInfo })
}

function cleanupMfaTokens() {
  const mfaTokens = new Map<string, string>(JSON.parse(window.localStorage.getItem("mfa-tokens") || "[]"))
  Array.from(mfaTokens.entries()).forEach(([key, value]: any) => {
    const tokenInfo = parseJwt(value)
    const pastExpiration = (tokenInfo.TrustedDeviceExpires ?? 0) < Date.now() / 1000
    if (pastExpiration) {
      mfaTokens.delete(key)
    }
  })
  localStorage.setItem("mfa-tokens", JSON.stringify(Array.from(mfaTokens.entries())))

  return mfaTokens
}

export const authorizeUserSchema = z.object({
  accessToken: z.string(),
  refreshToken: z.string(),
})

function authenticate(credentials: LoginCredentials) {
  const mfaTokens = cleanupMfaTokens()
  const token = mfaTokens.get(credentials.username)

  return vanillaClient("/users/authenticate", {
    body: credentials,
    headers: !!token ? { Authorization: `Bearer ${token}` } : undefined,
  }).then((res) => authorizeUserSchema.parse(res))
}

function refreshAccessToken(refreshToken: string) {
  return vanillaClient<AxiosResponse<any>>("/users/refreshAccessToken", {
    body: { refreshToken },
    validateStatus: (status: number) => (status >= 200 && status < 300) || status === 401,
    dataOnly: false,
  })
}

function resendCode(sid: string, email: string) {
  return vanillaClient("/users/ResendMfaCode", { body: { sid: sid, email: email } })
}

function logout({ isTimeout = false }: { isTimeout: boolean }) {
  return vanillaClient("/account/updateSession", { body: { isTimedout: isTimeout, isLogout: !isTimeout } })
}

export { signup, authenticate, refreshAccessToken, resendCode, logout }
