import { useSetLoading } from "@/utils/useLoader"
import React from "react"
import { useHistory } from "react-router"
import { useAuthStore } from "../../context/AuthContext"

interface ISSOLogin {
  accessToken: string
  refreshToken: string
}

const SSOLogin: React.FC<ISSOLogin> = ({ accessToken, refreshToken }: ISSOLogin) => {
  const loginSSO = useAuthStore((state) => state.loginSSO)
  const history = useHistory()
  const setLoading = useSetLoading()

  setLoading(true)
  loginSSO(accessToken, refreshToken)
  setLoading(false)
  history.push("/")
  return <div>Loading...</div>
}

export default SSOLogin
