import React from "react"
import { constructError } from "../../utils/ErrorUtils"

const ErrorComponent: React.FC<IErrorComponent> = ({ error }: any) => {
  const formattedError = constructError?.(error)

  return (
    <div className="error-component">
      <h1>Oops. Something went wrong 😞</h1>
      <p className="mb-0">{formattedError.message}</p>
    </div>
  )
}

interface IErrorComponent {
  error: any
}

export default ErrorComponent
