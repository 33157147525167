const getApiUrl = () => {
  if (import.meta.env.VITE_UI_API_OVERRIDE) {
    return import.meta.env.VITE_UI_API_OVERRIDE.trim()
  }

  return (window as any)["runConfig"].apiUrl
}

console.log(`API URL: ${getApiUrl()}`)

export const cachingEnabled = true
export const apiUrl = getApiUrl()
