import { ReactElement, useCallback, useEffect, useState } from "react"
import packageJson from "../../../package.json"

import { toast } from "react-toastify"
import { useIsAuthenticated } from "../../context/AuthContext"
import { useCloseModal } from "../../utils/useCloseModal"
;(window as any).appVersion = packageJson.version

interface ICacheBuster {
  children: ReactElement
}

interface RequestUpdate {
  currentVersion: string
  latestVersion: string
  prompt: "none" | "requested" | "pending" | "denied" | "forced"
}

const doRefresh = () => {
  try {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (const name of names) caches.delete(name)
      })
    }
  } catch (e) {
    console.error("Failed to clear service worked caches")
  }
  setTimeout(() => {
    // If force reload fails
    window.location.reload()
  }, 5000)
  const location = window.location as any
  location.reload(true)
}

const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g)

  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())

    const b = Number(versionsB.shift())
    // eslint-disable-next-line no-continue
    if (a === b) continue
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b)
  }
  return false
}

const CacheBuster = ({ children }: ICacheBuster) => {
  const isAuthenticated = useIsAuthenticated()

  const [requestUpdate, setRequestUpdate] = useState<RequestUpdate>({
    currentVersion: "unkown",
    latestVersion: "unkown",
    prompt: "none",
  })

  useEffect(() => {
    if (requestUpdate.prompt === "requested") {
      toast.info("A new version of the app has been detected. Refreshing in 5 seconds...")
      setTimeout(() => {
        setRequestUpdate({ ...requestUpdate, prompt: "forced" })
      }, 5000)
    } else if (requestUpdate.prompt === "forced") {
    }
  }, [setRequestUpdate, requestUpdate])

  useEffect(() => {
    if ((!isAuthenticated && requestUpdate.prompt === "requested") || requestUpdate.prompt === "forced") {
      doRefresh()
    }
  }, [isAuthenticated, requestUpdate])

  const checkForUpdate = useCallback(() => {
    fetch("/meta.json", { cache: "no-store" })
      .then((res) => res.json())
      .then((res) => {
        const latestVersion = res.version.replace("-hotfix", "")
        const currentVersion = (window as any).appVersion.replace("-hotfix", "")

        if (semverGreaterThan(latestVersion, currentVersion)) {
          if (!isAuthenticated) {
            doRefresh()
            return
          }

          setRequestUpdate((oldVal) => {
            if (oldVal.prompt !== "none") {
              return oldVal
            }

            return {
              prompt: "requested",
              latestVersion: latestVersion,
              currentVersion: currentVersion,
            }
          })
        }
      })
  }, [setRequestUpdate, isAuthenticated])

  useEffect(() => {
    const oneTimeTimer = setTimeout(() => {
      checkForUpdate()
    }, 5 * 1000)
    const timer = setInterval(() => {
      checkForUpdate()
    }, 300 * 1000)

    return () => {
      clearTimeout(oneTimeTimer)
      clearInterval(timer)
    }
  }, [checkForUpdate])

  useCloseModal("confirm-refresh-confirm")

  return <>{children}</>
}

export default CacheBuster
