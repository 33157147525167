import React, { useMemo } from "react"
import * as portals from "react-reverse-portal"

const ModalPortalContext = React.createContext<{ portalNode: portals.HtmlPortalNode<any> } | undefined>(undefined)

type IModalPortalProvider = {
  children: React.ReactNode
}

const ModalPortalProvider: React.FC<IModalPortalProvider> = ({ children }) => {
  const portalNode = useMemo(() => portals.createHtmlPortalNode(), [])

  return (
    <ModalPortalContext.Provider
      value={{
        portalNode: portalNode,
      }}
    >
      {children}
    </ModalPortalContext.Provider>
  )
}

function useModalPortal() {
  const context = React.useContext(ModalPortalContext)
  if (context === undefined) {
    throw new Error("useModalPortal must be used within a ModalPortalProvider")
  }
  return context
}

export { ModalPortalProvider, useModalPortal }
