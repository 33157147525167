export const getDate = (date?: string) => {
  if (!date) {
    return ""
  }
  return new Date(date).toISOString().slice(0, 10)
}

export const parseDate = (time?: string) => {
  if (!time) {
    return undefined
  }

  return new Date(time)
}

export const forceParseUTC = (time?: string) => {
  if (!time) {
    return undefined
  }

  const date = new Date(time)
  date.setUTCHours(date.getHours())
  date.setUTCMinutes(date.getMinutes())
  date.setUTCSeconds(date.getSeconds())

  return date
}

export const getNoConvertDate = (date?: string) => {
  if (!date) {
    return ""
  }

  return date.slice(0, 10)
}

export const getTimePartLocal = (date?: Date) => {
  if (!date) {
    return ""
  }

  return date.getHours().toString().padStart(2, "0") + ":" + date.getMinutes().toString().padStart(2, "0")
}

export const createDateFromUTCTime = (time?: string) => {
  if (!time) {
    return undefined
  }

  const date = new Date()
  date.setUTCHours(parseInt(time.split(":")[0]))
  date.setUTCMinutes(parseInt(time.split(":")[1]))

  return date
}

export const createDateFromLocalTime = (time?: string) => {
  if (!time) {
    return undefined
  }

  const date = new Date()
  date.setHours(parseInt(time.split(":")[0]))
  date.setMinutes(parseInt(time.split(":")[1]))

  return date
}

export const getDateFormat = (
  year: string,
  month: string,
  day: string,
  separator: string,
  hour: string,
  minute: string,
  second: string,
) => {
  return `${year}${year && month ? "-" : ""}${month}${month && day ? "-" : ""}${day}${separator}${hour}${
    hour && minute ? ":" : ""
  }${minute}${minute && second ? ":" : ""}${second}`
}

export const getNewFilePattern = (
  filePattern: string,
  replaceWith: string,
  addDatePrefix: boolean,
  addDateSuffix: boolean,
  addPrefix: boolean,
  prefixStr: string,
  addSuffix: boolean,
  suffixStr: string,
  extensionStr: string,
  isAddExtension: boolean,
  isReplaceExtension: boolean,
  overwriteFileName: boolean,
  overwriteWith: string,
  year: string,
  month: string,
  day: string,
  separator: string,
  hour: string,
  minute: string,
  second: string,
) => {
  const pattern = getDateFormat(year, month, day, separator, hour, minute, second)

  const datePrefix = addDatePrefix ? pattern : ""
  const dateSuffix = addDateSuffix ? pattern : ""

  return `${datePrefix}${addPrefix ? prefixStr : ""}${
    overwriteFileName ? overwriteWith : `${filePattern === "" ? "filename" : `*${replaceWith}*`}`
  }${addSuffix ? suffixStr : ""}${dateSuffix}${isReplaceExtension ? `.${extensionStr}` : ""}${
    isAddExtension ? `.ext.${extensionStr}` : ""
  }${!isAddExtension && !isReplaceExtension ? ".ext" : ""}`
}

export const getHumanFileSize = (bytes: number, si = true, dp = 1, suffix = "B", space = true) => {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + (space ? " " : "") + suffix
  }

  const units = si ? ["K", "M", "G", "T", "P", "E", "Z", "Y"] : ["K", "M", "G", "T", "P", "E", "Z", "Y"]
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

  return bytes.toFixed(dp) + (space ? " " : "") + units[u] + suffix
}

function convertDuration(duration: number) {
  // Define the constants for the durations of each time unit in milliseconds
  const MS_PER_SECOND = 1000
  const MS_PER_MINUTE = MS_PER_SECOND * 60
  const MS_PER_HOUR = MS_PER_MINUTE * 60
  const MS_PER_DAY = MS_PER_HOUR * 24
  const MS_PER_MONTH = MS_PER_DAY * 30 // Approximation of a month as 30 days

  // Calculate the time units starting from the largest to the smallest
  const months = Math.floor(duration / MS_PER_MONTH)
  duration -= months * MS_PER_MONTH

  const days = Math.floor(duration / MS_PER_DAY)
  duration -= days * MS_PER_DAY

  const hours = Math.floor(duration / MS_PER_HOUR)
  duration -= hours * MS_PER_HOUR

  const minutes = Math.floor(duration / MS_PER_MINUTE)
  duration -= minutes * MS_PER_MINUTE

  const seconds = Math.floor(duration / MS_PER_SECOND)
  duration -= seconds * MS_PER_SECOND

  // The remaining duration is the milliseconds
  const milliseconds = duration

  // Return the calculated time units as an object
  return {
    months,
    days,
    hours,
    minutes,
    seconds,
    milliseconds,
  }
}

export const getHumanDurationString = (milliseconds: number) => {
  if (!milliseconds || milliseconds === 0) {
    return "0 seconds"
  }

  const { months, days, hours, minutes, seconds, milliseconds: parsedMilliseconds } = convertDuration(milliseconds)

  const monthsStr = months !== 0 ? `${months} month${months !== 1 ? "s" : ""}` : ""
  const dayStr = days !== 0 ? `${days} day${days !== 1 ? "s" : ""}` : ""
  const hourStr = hours !== 0 ? `${hours} hour${hours !== 1 ? "s" : ""}` : ""
  const minuteStr = minutes !== 0 ? `${minutes} minute${minutes !== 1 ? "s" : ""}` : ""
  const secondStr = seconds !== 0 ? `${seconds} second${seconds !== 1 ? "s" : ""}` : ""
  const millisecondStr = milliseconds < 1000 ? `${parsedMilliseconds} ms` : ""

  return [monthsStr, dayStr, hourStr, minuteStr, secondStr, millisecondStr].filter((str) => str !== "").join(" ")
}
