import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { hasViewPermission } from "@/context/AuthContext"
import { faBan } from "@fortawesome/pro-duotone-svg-icons"
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ReactNode } from "react"
import { useModal } from "../../context/ModalContext"
import DeleteModal from "../delete-modal/DeleteModal"

export type ActionItem<T> = {
  title: string
  icon: ReactNode
  onClick: (data: T) => void
  confirm?: {
    entityTypeName: string
    entityName?: string
  }
  bulkSupported?: boolean
  getBulkName?: (data: any) => string
  handleBulkExport?: (data: T[]) => { title: "Success" | "Failed"; message?: string | undefined }[]
  permissionCodes?: number[]
}

interface IRowActions<T> {
  generateActions?: (data: T) => ActionItem<T>[]
  data: T
  idPrefix?: string
  index?: number
}

function RowActions<T>({ generateActions, data, idPrefix, index }: IRowActions<T>) {
  const actions = generateActions?.(data)
  const { createModal } = useModal()

  let generatedActions = actions
    ?.filter((action) => hasViewPermission(action.permissionCodes))
    .map((action, index) => {
      return (
        <DropdownMenuItem
          key={index}
          id={`action-${action.title.toLowerCase().replaceAll(" ", "-")}`}
          onClick={() => {
            action.confirm
              ? createModal(
                  <DeleteModal
                    entityTypeName={action.confirm.entityTypeName}
                    entityName={action.confirm.entityName}
                    doDelete={() => action.onClick(data)}
                  />,
                )
              : action.onClick(data)
          }}
        >
          {action.icon}
          <div className="ml-2">{action.title}</div>
        </DropdownMenuItem>
      )
    })

  if (generatedActions?.length === 0) {
    generatedActions = [
      <DropdownMenuItem key={0} className="dropdown-item v2-flat-button disabled flex">
        <FontAwesomeIcon icon={faBan} />
        <div>No actions available</div>
      </DropdownMenuItem>,
    ]
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger id={`${index}-navbarDropdown-${idPrefix ? idPrefix : ""}`} asChild>
        <Button className="grid h-[2.85rem] w-[2.85rem] items-center" variant="ghost">
          <FontAwesomeIcon icon={faEllipsisV} className="pointer-events-none text-thru-link" size="lg" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" alignOffset={22.8} sideOffset={-22.8} side="left">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {generatedActions}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default RowActions
