import { cn } from "@/lib/utils"
import * as React from "react"
import { Link as ReactLink } from "react-router-dom"

export interface StyledLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string
  applyStyles?: boolean
}

const StyledLink = React.forwardRef<HTMLAnchorElement, StyledLinkProps>(
  ({ className, to, applyStyles = true, ...props }, ref) => {
    return (
      <ReactLink
        to={to}
        className={cn(className, applyStyles && "text-thru-link hover:text-accent-thru-link hover:underline")}
        ref={ref}
        {...props}
      />
    )
  },
)
StyledLink.displayName = "StyledLink"

export { StyledLink }
