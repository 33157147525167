import React, { ReactNode } from "react"

import { toast } from "react-toastify"
import { DEFAULT_ERROR } from "./constants"

const getErrorMessage = (error: any) => {
  return constructError(error).message
}

function constructError(error: any) {
  const returnError = {
    error: true,
    message: error.message || DEFAULT_ERROR,
    type: error.type,
    statusCode: null,
  }

  if (error?.response?.data && !error.type) {
    if (error.response.data.message) {
      try {
        const json = JSON.parse(error.response.data.message)

        if (!json.message) {
          throw "Message is not JSON"
        }
        returnError.message = json.message
      } catch {
        returnError.message = error.response.data.message
      }
    } else if (error?.response?.data?.errors?.key?.length ?? 0 !== 0) {
      returnError.message = error.response.data.errors.key.join("\n")
    } else if (
      !!error?.response?.data &&
      (typeof error?.response?.data === "string" || error?.response?.data instanceof String)
    ) {
      returnError.message = error?.response?.data
    }
  }
  if (error && error.response) {
    returnError.statusCode = error.response.status
  }

  if (returnError.message == DEFAULT_ERROR && (typeof error === "string" || error instanceof String) && !!error) {
    returnError.message = error
  }

  return returnError
}

const getErrorContent = (title: string, e: any) => {
  const error = React.isValidElement(e) ? e : <p className="sub-heading">{constructError(e).message}</p>

  return (
    <>
      <p>{title}</p>
      {error}
    </>
  )
}

function showToast(title: string, e: any) {
  showWarning(getErrorContent(title, e))
}

function showWarning(content: ReactNode, autoClose: false | number = false) {
  toast.warning(content, { autoClose: autoClose })
}

export { constructError, showToast, showWarning, getErrorMessage, getErrorContent }
