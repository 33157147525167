import * as portals from "react-reverse-portal"
import { useBulkActionPortal } from "../../context/BulkActionPortalContext"
import { useBulkActions } from "../searchable-table/SearchableTable"

const BulkActionsBarPortal = () => {
  const { portalNode: bulkActionPortalNode } = useBulkActionPortal()
  const bulkActive = useBulkActions((state) => state.active)

  return <portals.OutPortal node={bulkActionPortalNode} />
}

export default BulkActionsBarPortal
