import { faSync } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react"
import Tooltip from "../tooltip/Tooltip"
interface IRefreshIcon {
  isLoading: boolean
  idPrefix?: string
  refresh: () => any
  iconStyle?: any
}

const RefreshIcon: React.FC<IRefreshIcon> = ({ idPrefix, isLoading, refresh, iconStyle }: IRefreshIcon) => {
  const getIcon = () => {
    return document.getElementById(`${idPrefix}-refresh`)
  }
  const [refreshAnimation, setRefreshAnimation] = useState(false)

  const onAnimationIteration = useCallback(() => {
    setRefreshAnimation(isLoading)
    const icon = getIcon()

    if (!icon) {
      return
    }

    if (isLoading) {
      icon.style.color = "grey"
      icon.classList.remove("rotate")
      void icon.offsetWidth
      setTimeout(() => {
        icon.classList.add("rotate")
      }, 1)
    } else {
      setTimeout(() => {
        const newIcon = getIcon()
        if (newIcon) {
          icon.classList.remove("rotate")
          newIcon.style.color = ""
        }
      }, 50)
    }
  }, [isLoading, setRefreshAnimation, idPrefix])

  useLayoutEffect(() => {
    const currIcon = getIcon()
    if (refreshAnimation && currIcon) {
      currIcon.classList.add("rotate")
      setTimeout(() => {
        const newIcon = getIcon()
        if (newIcon) {
          newIcon.style.color = "grey"
        }
      }, 50)
    }
  }, [refreshAnimation, idPrefix])

  useLayoutEffect(() => {
    if (isLoading) {
      setRefreshAnimation(true)
    }
  }, [isLoading])

  const refreshIcon = useMemo(() => {
    return (
      <FontAwesomeIcon
        className="ghost-refresh mr-2 text-primary"
        key={`${idPrefix}-refresh`}
        id={`${idPrefix}-refresh`}
        style={{ fontSize: "1rem", ...iconStyle }}
        icon={faSync}
      />
    )
  }, [idPrefix])

  return (
    <Tooltip title="Refresh">
      <div
        onClick={() => refresh()}
        onAnimationEnd={() => {
          onAnimationIteration()
        }}
      >
        {refreshIcon}
      </div>
    </Tooltip>
  )
}

export default RefreshIcon
