import React from "react"
import { Link, useHistory } from "react-router-dom"
import { useModal } from "../../context/ModalContext"
import DiscardModal from "../discard-modal/DiscardModal"

interface IListCancelButton {
  isDirty: boolean
  to?: string
  goBack?: boolean
}

const ListCancelButton: React.FC<IListCancelButton> = ({ isDirty, to, goBack }: IListCancelButton) => {
  const { createModal, closeModal } = useModal()
  const history = useHistory()

  const button = (
    <button
      id="btnCancel"
      onClick={() => {
        isDirty &&
          createModal(
            <DiscardModal
              discardConfirm={async () => {
                history.push(to ?? "")
              }}
            />,
          )
      }}
      type="button"
      className="btn btn-label-thru"
    >
      CANCEL
    </button>
  )

  if (!isDirty && goBack && !(history.length <= 2 && to)) {
    return React.cloneElement(button, {
      ...button.props,
      onClick: (e: any) => {
        history.goBack()
        button.props?.onClick?.(e)
      },
    })
  }
  if (!isDirty && to) {
    return <Link to={to}>{button}</Link>
  }

  return button
}

export default ListCancelButton
