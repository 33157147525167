import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog"
import * as React from "react"

import { Button, buttonVariants } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import { cva, VariantProps } from "class-variance-authority"
import { X } from "lucide-react"

const AlertDialog = AlertDialogPrimitive.Root

const AlertDialogTrigger = AlertDialogPrimitive.Trigger

const AlertDialogPortal = AlertDialogPrimitive.Portal

const AlertDialogOverlay = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Overlay
    className={cn(
      "fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    )}
    {...props}
    ref={ref}
  />
))
AlertDialogOverlay.displayName = AlertDialogPrimitive.Overlay.displayName

export const alertDialogSizeVariants = cva("", {
  variants: {
    size: {
      default: "max-w-[calc(min(100vw-2rem-2.5%,52rem))]",
      sm: "max-w-sm",
      md: "max-w-md",
      lg: "max-w-lg",
      xl: "max-w-xl",
      [`2xl`]: "max-w-2xl",
      [`3xl`]: "max-w-3xl",
      [`4xl`]: "max-w-4xl",
      [`5xl`]: "max-w-5xl",
      [`6xl`]: "max-w-6xl",
    },
  },
  defaultVariants: {
    size: "default",
  },
})

export const alertDialogPlacementVariants = cva("", {
  variants: {
    align: {
      center: "grid place-items-center",
      top: "grid item-start justify-items-center mt-4",
    },
  },
  defaultVariants: {
    align: "center",
  },
})

export interface AlertDialogContentProps
  extends React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content>,
    VariantProps<typeof alertDialogSizeVariants>,
    VariantProps<typeof alertDialogPlacementVariants> {}

const AlertDialogContent = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Content>,
  AlertDialogContentProps
>(({ className, children, size, align, ...props }, ref) => (
  <AlertDialogPortal>
    <AlertDialogOverlay />
    <div className={cn("absolute inset-0", alertDialogPlacementVariants({ align }))}>
      <AlertDialogPrimitive.Content
        ref={ref}
        className={cn(
          alertDialogSizeVariants({ size }),
          "overflow-y-auto max-h-[calc(100vh-2rem)] bg-background rounded fixed z-50 w-full dark:border shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
          className,
        )}
        {...props}
      >
        {children}
      </AlertDialogPrimitive.Content>
    </div>
  </AlertDialogPortal>
))
AlertDialogContent.displayName = AlertDialogPrimitive.Content.displayName

export const alertDialogHeaderVariants = cva(
  "flex justify-between items-center py-3 px-6 rounded rounded-b-none text-center sm:text-left text-primary-foreground bg-primary translate-y-[-1px]",
  {
    variants: {
      variant: {
        default: "flex justify-between items-center rounded-b-none text-center sm:text-left bg-primary",
        warning: "flex justify-between items-center rounded-b-none text-center sm:text-left bg-amber-500",
        destructive: "flex justify-between items-center rounded-b-none text-center sm:text-left bg-destructive",
        target: "flex justify-between items-center rounded-b-none text-center sm:text-left bg-target",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
)

export interface AlertDialogHeaderProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof alertDialogHeaderVariants> {}

const AlertDialogHeader = ({ className, variant, children, ...props }: AlertDialogHeaderProps) => (
  <div className={cn(alertDialogHeaderVariants({ variant, className }), className)} {...props}>
    {children}
    <AlertDialogPrimitive.Cancel
      asChild
      className="opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
    >
      <Button size="icon" variant={variant} className="hover:bg-white/20">
        <X className="h-4 w-4" />
        <span className="sr-only">Close</span>
      </Button>
    </AlertDialogPrimitive.Cancel>
  </div>
)
AlertDialogHeader.displayName = "AlertDialogHeader"

const AlertDialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className)} {...props} />
)
AlertDialogFooter.displayName = "AlertDialogFooter"

const AlertDialogTitle = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Title ref={ref} className={cn("text-lg font-semibold", className)} {...props} />
))
AlertDialogTitle.displayName = AlertDialogPrimitive.Title.displayName

const AlertDialogDescription = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Description ref={ref} className={cn("text-sm text-muted-foreground", className)} {...props} />
))
AlertDialogDescription.displayName = AlertDialogPrimitive.Description.displayName

const AlertDialogAction = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Action>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Action>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Action ref={ref} className={cn(buttonVariants(), className)} {...props} />
))
AlertDialogAction.displayName = AlertDialogPrimitive.Action.displayName

const AlertDialogCancel = React.forwardRef<
  React.ElementRef<typeof AlertDialogPrimitive.Cancel>,
  React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Cancel>
>(({ className, ...props }, ref) => (
  <AlertDialogPrimitive.Cancel
    ref={ref}
    className={cn(buttonVariants({ variant: "outline" }), "mt-2 sm:mt-0", className)}
    {...props}
  />
))
AlertDialogCancel.displayName = AlertDialogPrimitive.Cancel.displayName

export {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
}
