import * as React from "react"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip"

interface HtmlTooltipProps {
  title: React.ReactNode
  children?: React.ReactNode
}

const HtmlTooltip: React.FC<HtmlTooltipProps> = ({ title, children }: HtmlTooltipProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent>{title}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

HtmlTooltip.displayName = "HtmlTooltip"

export { HtmlTooltip }
