import { z } from "zod"
import { ChangePasswordModel } from "../../types/ChangePasswordModel"
import { ProfileModel } from "../../types/ProfileModel"
import { UserSessionModel } from "../../types/UserSessionModel"
import client from "../../utils/ApiClient"
import vanillaClient from "../../utils/AxiosClient"

const _account = "/account"

const getUserProfile = () => {
  return vanillaClient<ProfileModel>(`${_account}`)
}

const updateUserProfile = (profile: ProfileModel) => {
  return client(`${_account}/updateProfile`, { method: "PUT", body: profile })
}

export const changePasswordErrorSchema = z.object({
  type: z.string(),
  title: z.string(),
  status: z.number(),
  errors: z.object({
    NewPassword: z.array(z.string()).optional(),
    NewPasswordHash: z.array(z.string()).optional(),
    ConfirmPassword: z.array(z.string()).optional(),
  }),
})

const changePassword = (changePassword: ChangePasswordModel) => {
  return vanillaClient(`${_account}/changePassword`, {
    method: "POST",
    body: changePassword,
  })
}

const updateSession = (userSessionModel: UserSessionModel) => {
  return vanillaClient(`${_account}/updateSession`, {
    method: "POST",
    body: userSessionModel,
  })
}

export { getUserProfile, updateUserProfile, changePassword, updateSession }
