import { useEffect } from "react"
import { useAuthStore } from "../context/AuthContext"

const getScheduleRefreshTime = (exp?: number) => {
  if (!exp) {
    return undefined
  }

  return new Date(exp * 1000).getTime() - 120000
}

export const useRefreshAccessToken = () => {
  const scheduledRefreshTime = useAuthStore((state) => getScheduleRefreshTime(state.currentUser?.exp))

  useEffect(() => {
    if (!scheduledRefreshTime) {
      return
    }

    const interval = setTimeout(
      () => {
        navigator.locks.request("refresh_lock", async (lock) => {
          // Get latest expire time (in case someone else refreshed the token)
          useAuthStore.persist.rehydrate()
          const newRefreshTime = getScheduleRefreshTime(useAuthStore.getState().currentUser?.exp)

          if (newRefreshTime !== scheduledRefreshTime) {
            // Someone else refreshed the token. Nothing to do.
            return
          }

          await useAuthStore.getState().refreshAccessToken()
        })
      },
      Math.max(scheduledRefreshTime - Date.now(), 0),
    )

    return () => {
      clearTimeout(interval)
    }
  }, [scheduledRefreshTime])
}
