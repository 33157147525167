import React, { useEffect, useLayoutEffect, useState } from "react"

interface IDropdownContext {
  dropdown: any
  setDropdown: any
}

const DropdownContext = React.createContext<IDropdownContext | undefined>(undefined)

export const DropdownPortal = () => {
  const { dropdown, setDropdown } = useDropdownPortal()
  const [offsetY, setOffsetY] = useState(0)
  const [transitionOffset, setTransitionOffset] = useState(100)
  const [index, setIndex] = useState(0)

  useLayoutEffect(() => {
    const dropdownBottom = (document.getElementById("dropdown-portal")?.offsetHeight ?? 0) + dropdown?.top
    let offsetY = dropdownBottom - window.scrollY - (window.innerHeight - 16)

    if (offsetY < 0) {
      offsetY = 0
    }
    setOffsetY(offsetY)
  }, [dropdown])

  useLayoutEffect(() => {
    setIndex((index) => index + 1)
    if (!!dropdown?.element) {
      setTransitionOffset(0)
    } else {
      setTransitionOffset(100)
    }
  }, [dropdown?.element])

  useLayoutEffect(() => {
    if (!dropdown) {
      return
    }

    const onResize = (e: UIEvent) => {
      setDropdown((e: any) => {
        return { ...e, element: undefined }
      })
    }

    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [dropdown])

  return (
    <div
      id="dropdown-portal"
      tabIndex={0}
      key={index}
      className="absolute pointer-events-none"
      style={{
        top: (dropdown?.top ?? 0) - offsetY + transitionOffset,
        transition: "top 200ms ease, opacity 2s ease",
        opacity: transitionOffset === 0 ? 1 : 0,
        left: dropdown?.left ?? 0,
        zIndex: 1000,
        transform: dropdown?.translate,
      }}
    >
      {dropdown?.element}
    </div>
  )
}

export const DropdownAutoBound = ({ top, left, children }: any) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(true)
  }, [])

  return React.Children.map(children, (child) =>
    React.cloneElement(child, { ...child.props, style: { ...child.props.style, opacity: visible ? 1 : 0 } }),
  )
}

interface IDropdownProvider {
  children: React.ReactNode
}

export const DropdownProvider = ({ children }: IDropdownProvider) => {
  const context = React.useContext(DropdownContext)

  const [dropdown, setDropdown] = useState({ element: undefined, top: 0, left: 0 })

  return (
    <DropdownContext.Provider
      value={{
        dropdown,
        setDropdown,
      }}
    >
      {children}
    </DropdownContext.Provider>
  )
}

export function useDropdownPortal() {
  const context = React.useContext(DropdownContext)
  if (context === undefined) {
    throw new Error("useDropdownPortal must be used within a DropdownProvider")
  }

  useEffect(() => {
    return () => {
      context.setDropdown((dropdown: any) => {
        return { ...dropdown, element: undefined }
      })
    }
  }, [])

  return context
}
