$(document).on("show.bs.modal", ".modal", function () {
  $(this).find("[autofocus]").focus()

  const zIndex = 1040 + 10 * $(".modal:visible").length
  $(this).css("z-index", zIndex)
  setTimeout(() =>
    $(".modal-backdrop")
      .not(".modal-stack")
      .css("z-index", zIndex - 1)
      .addClass("modal-stack"),
  )
})
