import React from "react"
import { SkeletonTheme } from "react-loading-skeleton"
import { useActiveTheme } from "./theme-provider"

interface SkeletonThemeProviderProps {
  children: React.ReactNode
}

export function SkeletonThemeProvider({ children }: SkeletonThemeProviderProps) {
  const activeTheme = useActiveTheme()

  return (
    <SkeletonTheme
      baseColor={activeTheme === "dark" ? "hsl(var(--background))" : undefined}
      highlightColor={activeTheme === "dark" ? "hsl(var(--input))" : undefined}
    >
      {children}
    </SkeletonTheme>
  )
}
