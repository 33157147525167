import "./index.css"

import { createRoot } from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import App from "./App"
import { logWebVitals } from "./components/error-boundary/MonitoringClient"
import { SiteSettingsProvider } from "./context/SiteSettingsContext"
import reportWebVitals from "./reportWebVitals"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(
  <SiteSettingsProvider>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </SiteSettingsProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(logWebVitals)
