import React from "react"
import { FormProvider, useForm, UseFormReturn } from "react-hook-form"

type IThruFormContext = {
  loading: boolean
  idPrefix?: number
  requiredPermissionsForWrite?: number[]
}

const ThruFormContext = React.createContext<IThruFormContext | undefined>(undefined)

type IThruFormProvider = {
  customForm?: UseFormReturn<Record<string, any>>
  onSubmit: (data: any) => void
  children: React.ReactNode
  className?: string
  renderForm?: boolean
  loading: boolean
  idPrefix?: number
  autoComplete?: string
  requiredPermissionsForWrite?: number[]
  preventUnsavedChanges?: boolean
}

const FormPreserve = () => {
  // const {
  //   formState: { isDirty },
  // } = useFormContext()
  // useFormPreserve(isDirty)

  return <></>

  // return <Prompt when={isDirty} message={(location) => `You have unsaved changes, are you sure you want to leave?`} />
}

function ThruFormProvider({
  customForm,
  className,
  onSubmit,
  children,
  loading,
  idPrefix,
  autoComplete,
  renderForm = true,
  requiredPermissionsForWrite,
  preventUnsavedChanges = true,
}: IThruFormProvider) {
  const newForm = useForm()
  const form = customForm ?? newForm
  const { handleSubmit } = form

  return (
    <FormProvider {...form}>
      <ThruFormContext.Provider
        value={{
          loading: loading,
          idPrefix: idPrefix,
          requiredPermissionsForWrite,
        }}
      >
        {preventUnsavedChanges && <FormPreserve />}
        {renderForm && (
          <form
            autoComplete={autoComplete}
            className={className}
            onSubmit={handleSubmit(async (data: any) => {
              if (document.activeElement instanceof HTMLElement) {
                Object.entries(data).forEach(([key, value]) => {
                  if (typeof value === "string") {
                    data[key] = value.trim()
                  }
                })
              }

              return await onSubmit(data)
            })}
          >
            {children}
          </form>
        )}
        {!renderForm && children}
      </ThruFormContext.Provider>
    </FormProvider>
  )
}

function useThruFormContext() {
  const context = React.useContext(ThruFormContext)

  if (context === undefined) {
    throw new Error("useThruFormContext must be used within a ThruFormProvider")
  }

  return context
}

export { ThruFormProvider, useThruFormContext }
