import { Pagination, PaginationContent, PaginationItem, PaginationLink } from "@/components/ui/pagination"
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { MaterialButton } from "../material-button/MaterialButton"

interface PaginationProps {
  pages: number
  currentPage: number
  setCurrentPage: (page: number) => void
  pageRange?: number
}

const PaginationBar: React.FC<PaginationProps> = ({
  pages,
  currentPage,
  setCurrentPage,
  pageRange = 3,
}: PaginationProps) => {
  return (
    <Pagination>
      <PaginationContent>
        <MaterialButton>
          <PaginationLink>
            <PaginationItem
              onClick={() => {
                setTimeout(() => {
                  setCurrentPage(0)
                }, 50)
              }}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </PaginationItem>
          </PaginationLink>
        </MaterialButton>
        {Array.from(Array(pages))
          .map((_, index) => {
            if (pageRange === -1) {
              return { ..._, index: index }
            }

            if (index === 0) {
              return { ..._, index: index }
            }

            if (index === pages - 1) {
              return { ..._, index: index }
            }

            if (Math.abs(index - currentPage) <= pageRange) {
              return { ..._, index: index }
            }
            if (index === 1) {
              return { ..._, key: "break-start", index: "..." }
            }
            if (index === pages - 2) {
              return { ..._, key: "break-end", index: "..." }
            }
            return undefined
          })
          .filter((item) => {
            return !!item
          })
          .map((item) => {
            return (
              <MaterialButton key={item.key ?? item.index}>
                <PaginationItem
                  onClick={() => {
                    setTimeout(() => {
                      if (typeof item.index === "number") {
                        setCurrentPage(item.index)
                      }
                    }, 50)
                  }}
                >
                  <PaginationLink isActive={currentPage === item.index}>
                    {typeof item.index === "number" ? item.index + 1 : item.index}
                  </PaginationLink>
                </PaginationItem>
              </MaterialButton>
            )
          })}
        <MaterialButton>
          <PaginationItem
            onClick={() => {
              setTimeout(() => {
                setCurrentPage(pages - 1)
              }, 50)
            }}
          >
            <PaginationLink>
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </PaginationLink>
          </PaginationItem>
        </MaterialButton>
      </PaginationContent>
    </Pagination>
  )
}

export default PaginationBar
