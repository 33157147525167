import { z } from "zod"

export const loginMotdSchema = z.object({
  id: z.number(),
  message: z.string(),
  textColor: z.string(),
  activeStartDate: z.string().transform((val) => new Date(val)),
  activeEndDate: z.string().transform((val) => new Date(val)),
  domainName: z.string(),
})
export type LoginMotd = z.infer<typeof loginMotdSchema>
