import { Button } from "@/components/ui/button"
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { getErrorMessage } from "@/utils/ErrorUtils"
import React from "react"
import { useAuthStore } from "../../context/AuthContext"
import { getHumanDurationString } from "../../utils/FormatterUtil"

type IMfaRememberDevice = {}

const MfaRememberDevice: React.FC<IMfaRememberDevice> = ({}) => {
  const authError = useAuthStore((state) => state.authError)

  const username = useAuthStore((state) => state.currentUser?.UserName)
  const rememberDevice = useAuthStore((state) => state.rememberMfaDevice)
  const skipRememberDevice = useAuthStore((state) => state.skipRememberMfaDevice)
  const trustedDeviceExpires = useAuthStore((state) =>
    !!state.currentUser?.TrustedDeviceExpires ? new Date(state.currentUser.TrustedDeviceExpires * 1000) : undefined,
  )
  const logout = useAuthStore((state) => state.logout)
  const millisecondsLeft = Math.max((trustedDeviceExpires?.getTime() ?? Date.now()) - Date.now(), 0)
  const ms = 1000 * 60 * 5
  const roundedMillisecondsLeft = Math.ceil(millisecondsLeft / ms) * ms
  const displayString = getHumanDurationString(roundedMillisecondsLeft)

  // Re-render every second
  const [, setUpdate] = React.useState(0)
  React.useEffect(() => {
    const interval = setInterval(() => {
      setUpdate((prev) => prev + 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <CardHeader>
        <CardTitle>Trust Device</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="login-form-tweak" style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
          <p className="mb-0">{username}</p>
          <div>
            <h3 className="thru-signin-title" style={{ textAlign: "left" }}>
              Trust this device for {displayString}?
            </h3>
            <p className="mb-0">
              If you choose to trust this device, you will not be asked for multi-factor authentication for{" "}
              {displayString}.
            </p>
          </div>
          <div id="error" className="error mt-4" style={{ display: "none", color: "red" }} />
          <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
            <div className="thru-login__actions">
              <Button
                id="btnRememberDevice"
                className="w-full"
                type="button"
                onClick={() => {
                  rememberDevice()
                }}
              >
                Trust Device
              </Button>
            </div>
            <div className="thru-login__actions">
              <Button
                id="btnSkipRememberDevice"
                className="w-full"
                variant="outline"
                type="button"
                onClick={async () => {
                  skipRememberDevice()
                }}
              >
                Not Now
              </Button>
            </div>
          </div>
          {authError && <div className="alert alert-danger mb-0">{getErrorMessage(authError)}</div>}
          <a
            className="block text-center text-thru-primary hover:text-accent-thru-primary hover:underline"
            href="#"
            id="cancel-sign-in"
            style={{ color: "var(--theme-thru-blue)" }}
            onClick={() => {
              logout({})
            }}
          >
            Cancel signing in
          </a>
        </div>
      </CardContent>
    </>
  )
}

export default MfaRememberDevice
