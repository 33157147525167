import React, { ReactElement, useLayoutEffect, useState } from "react"

export interface IFade {
  duration?: string
  children: ReactElement
}

const Fade: React.FC<IFade> = ({ children, duration = "400ms" }: IFade) => {
  const [show, setShow] = useState(false)
  useLayoutEffect(() => {
    setShow(true)
  }, [])

  return (
    <>
      {React.cloneElement(children, {
        style: { ...children.props?.style, opacity: show ? "1.0" : "0.0", transition: `opacity ${duration} ease` },
      })}
    </>
  )
}

export default Fade
