import { MantineProvider } from "@mantine/core"
import React from "react"
import { useActiveTheme } from "./theme-provider"

interface MantineThemeProviderProps {
  children: React.ReactNode
}

export const MantineThemeProvider: React.FC<MantineThemeProviderProps> = ({ children }) => {
  const activeTheme = useActiveTheme()

  return (
    <MantineProvider
      theme={{
        colorScheme: activeTheme === "dark" ? "dark" : "light",
        colors: {
          ...(activeTheme === "dark"
            ? {
                dark: [
                  "hsl(229.09090909090912, 15.068493150684917%, 85.68627450980392%)",
                  "hsl(233.6842105263158, 12.925170068027207%, 71.17647058823529%)",
                  "hsl(232.17391304347828, 11.1111111111111%, 59.411764705882355%)",
                  "hsl(233.0769230769231, 11.304347826086953%, 45.09803921568628%)",
                  "hsl(var(--input))",
                  "hsl(var(--background))",
                  "hsl(var(--background))",
                  "hsl(var(--background))",
                  "hsl(var(--background))",
                  "hsl(var(--background))",
                ],
              }
            : {}),
        },
      }}
    >
      {children}
    </MantineProvider>
  )
}
