import React from "react"
import InputLabel, { IInputLabel } from "./InputLabel"
import PasswordInput, { IPasswordInput } from "./PasswordInput"

type IPasswordInputLabel = IPasswordInput &
  IInputLabel & {
    defaultValue?: string
  }

const PasswordInputLabel: React.FC<IPasswordInputLabel> = ({
  label,
  required,
  name,
  position,
  defaultValue,
  fullWidth,
  ...rest
}: IPasswordInputLabel) => {
  return (
    <InputLabel
      name={name}
      label={label}
      htmlFor={name}
      required={required ?? false}
      position={position}
      fullWidth={fullWidth}
    >
      <PasswordInput {...rest} name={name} required={required} />
    </InputLabel>
  )
}

export default PasswordInputLabel
