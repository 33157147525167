import React from "react"
import * as portals from "react-reverse-portal"

const ScrollContext = React.createContext<{ portalNode: portals.HtmlPortalNode<any> } | undefined>(undefined)

type IScrollProvider = {
  children: React.ReactNode
  portalNode: any
}

const ScrollProvider: React.FC<IScrollProvider> = ({ children, portalNode }) => {
  return (
    <ScrollContext.Provider
      value={{
        portalNode: portalNode,
      }}
    >
      {children}
    </ScrollContext.Provider>
  )
}

function useScroll() {
  const context = React.useContext(ScrollContext)
  if (context === undefined) {
    throw new Error("useScroll must be used within a ScrollProvider")
  }
  return context
}

export { ScrollProvider, useScroll }
