import { create } from "zustand"
import { createJSONStorage, persist } from "zustand/middleware"

interface WhiteLabelStore {
  brand: "thru" | "boomi"
  cycleBrand: () => void
}

export const useWhiteLabelStore = create<WhiteLabelStore>()(
  persist(
    (set, get) => ({
      brand: "thru",
      cycleBrand: () => {
        set((state) => ({ brand: state.brand === "thru" ? "boomi" : "thru" }))
      },
    }),
    {
      name: "white-label",
      storage: createJSONStorage(() => localStorage),
    },
  ),
)
