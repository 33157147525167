import React from "react"
import * as portals from "react-reverse-portal"

const BulkActionPortalContext = React.createContext<{ portalNode: portals.HtmlPortalNode<any> } | undefined>(undefined)

type IBulkActionPortalProvider = {
  children: React.ReactNode
  portalNode: any
}

const BulkActionPortalProvider: React.FC<IBulkActionPortalProvider> = ({ children, portalNode }) => {
  return (
    <BulkActionPortalContext.Provider
      value={{
        portalNode: portalNode,
      }}
    >
      {children}
    </BulkActionPortalContext.Provider>
  )
}

function useBulkActionPortal() {
  const context = React.useContext(BulkActionPortalContext)
  if (context === undefined) {
    throw new Error("useBulkActionPortal must be used within a BulkActionPortalProvider")
  }
  return context
}

export { BulkActionPortalProvider, useBulkActionPortal }
