import { Button, ButtonProps } from "@/components/ui/button"
import { cn } from "@/lib/utils"
import React, { ReactNode } from "react"

type IModalButton = {
  id: string
  variant?: ButtonProps["variant"]
  children?: ReactNode
  onClick?: any
}
type IModalButtonProps = IModalButton & React.ButtonHTMLAttributes<HTMLButtonElement>
const ModalButton: React.FC<IModalButtonProps> = ({
  id,
  variant = "outline",
  children,
  onClick,
  ...rest
}: IModalButtonProps) => {
  return (
    <Button
      type="button"
      id={id}
      size="lg"
      variant={variant}
      data-dismiss={!onClick ? "modal" : undefined}
      className={cn("m-0", rest.className)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default ModalButton
