import { z } from "zod"
import { PasswordModel } from "../types/PasswordModel"
import vanillaClient from "./AxiosClient"

const _passwordUrl = "/Password"

const validateSetPswdLink = (token: string) => {
  return vanillaClient(`${_passwordUrl}/validate`, { key: token }).then((res) => {
    return passwordModelSchema.parse(res)
  })
}

const setPassword = (passwordModel: PasswordModel) => {
  return vanillaClient<any>(`${_passwordUrl}/setPassword`, {
    method: "POST",
    body: passwordModel,
  })
}

export const passwordModelSchema = z.object({
  key: z.string(),
  mfaEnabled: z.boolean(),
  username: z.string(),
  password: z.string().nullable(),
  confirmPassword: z.string().nullable(),
})

const validateResetPswdLink = (key: string) => {
  return vanillaClient(`${_passwordUrl}/validateReset`, { key: key }).then((res) => {
    return passwordModelSchema.parse(res)
  })
}

const resetPassword = (passwordModel: PasswordModel) => {
  return vanillaClient<any>(`${_passwordUrl}/resetPassword`, {
    method: "POST",
    body: passwordModel,
  })
}

export { validateSetPswdLink, setPassword, validateResetPswdLink, resetPassword }
