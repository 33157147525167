import axios, { AxiosResponse } from "axios"
import { useAuthStore } from "../context/AuthContext"
import { apiUrl } from "./config"

function client<T>(
  endpoint: any,
  { body, method, headers, ...customConfig }: any = {},
): {
  client: () => Promise<AxiosResponse<T>>
  key?: { url: string; params: any; method: string }
} {
  const url = `${apiUrl}${endpoint}`

  const config: any = {
    params: { ...customConfig },
  }

  const accessToken = useAuthStore.getState().currentUser?.accessToken

  if (accessToken) {
    config.headers = { Authorization: `Bearer ${accessToken}` }
  }

  if (headers) {
    config.headers = { ...config.headers, ...headers }
  }

  if (method === "PUT") {
    return { client: () => axios.put<T>(url, body, config), key: undefined }
  } else if (method === "DELETE") {
    return { client: () => axios.delete<T>(url, config), key: undefined }
  } else if (method === "POST" || body) {
    return { client: () => axios.post<T>(url, body, config), key: undefined }
  } else {
    return {
      client: () => axios.get<T>(url, config),
      key: { url: url, params: config.params, method: "get" },
    }
  }
}

export default client
