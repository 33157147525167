import { useModalHelper } from "@/utils/useModalHelper"
import { cloneElement, useCallback, useLayoutEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import { create } from "zustand"

interface Prompt {
  renderPrompt: (promptState: any, resolve: (value: any) => void, reject: (reason: any) => void) => React.ReactNode
  promptState: any
  id: string
  resolve: (value: any) => void
  reject: (reason: any) => void
  uuid?: string
}

interface ModadlPromptStore {
  renderKey: string | null
  setRenderKey: (key: string) => void
  prompts: Prompt[]
  nextPrompt: Prompt | undefined
  registerPrompt: (props: { renderPrompt: Prompt["renderPrompt"]; id: string; promptState?: any }) => Promise<boolean>
  popPrompt: () => void
}

export const useModalPrompt = create<ModadlPromptStore>()((set, get) => ({
  renderKey: null,
  setRenderKey: (key: string) => {
    set({ renderKey: key })
  },
  prompts: [],
  nextPrompt: get()?.prompts?.[0],
  registerPrompt: async ({ renderPrompt, id, promptState = {} }) => {
    return await new Promise((resolve, reject) => {
      set((state) => {
        return {
          prompts: [...state.prompts, { renderPrompt, id, promptState, resolve, reject, uuid: uuidv4() }],
        }
      })
    })
  },
  popPrompt: () => {
    set((state) => {
      return { prompts: state.prompts.slice(1) }
    })
  },
}))

export function PromptModal() {
  const popPrompt = useModalPrompt((state) => state.popPrompt)
  const prompts = useModalPrompt((state) => state.prompts)
  const setRenderKey = useModalPrompt((state) => state.setRenderKey)

  useLayoutEffect(() => {
    setTimeout(() => {
      setRenderKey(uuidv4())
    }, 10)
  }, [setRenderKey, prompts])

  const props = prompts?.[0]
  const { renderPrompt, promptState, id, uuid, resolve, reject } = props ?? {}
  const onHideModal = useCallback(() => {
    setTimeout(() => {
      resolve(false)
      popPrompt()
    }, 300)
  }, [reject, popPrompt])
  useModalHelper({
    modalId: id,
    onHideModal: onHideModal,
  })

  if (!props) {
    return <></>
  }

  return cloneElement(
    renderPrompt(
      promptState,
      (val) => {
        resolve(val)
        popPrompt()
      },
      (val) => {
        reject(val)
        popPrompt()
      },
    ) as React.ReactElement,
    { key: uuid },
  )
}
