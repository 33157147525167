import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { cn } from "@/lib/utils"
import { faRotateLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useFormContext, useFormState } from "react-hook-form"

const InputLabel: React.FC<IInputLabel> = ({
  label,
  name,
  required = false,
  position = "left",
  children,
  hasNote = false,
  fullWidth = false,
}: IInputLabel) => {
  const { resetField } = useFormContext()
  const { dirtyFields } = useFormState({ name: name })
  const isDirty = dirtyFields[name]

  if (position === "top") {
    return (
      <div className="flex" style={{ flexDirection: "column" }}>
        <Label style={{ marginBottom: hasNote ? "4px" : undefined }}>
          {label} {required && <i className="Thru-required">*</i>}
          {isDirty && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="text-xs h-[21px] w-[21px]"
                    type="button"
                    onClick={() => {
                      resetField(name)
                    }}
                  >
                    <FontAwesomeIcon icon={faRotateLeft} />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p className="mb-0">Undo</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </Label>
        {children}
      </div>
    )
  }

  return (
    <>
      <div className={cn("grid gap-2", fullWidth && "w-full")}>
        <Label style={{ marginBottom: hasNote ? "4px" : undefined }} htmlFor={name}>
          {label} {required && <i className="Thru-required">*</i>}
          {isDirty && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="ml-2 text-xs"
                    type="button"
                    style={{ height: 13, width: 13 }}
                    onClick={() => {
                      resetField(name)
                    }}
                  >
                    <FontAwesomeIcon icon={faRotateLeft} className="text-xs" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p className="mb-0">Undo</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </Label>
        {children}
      </div>
    </>
  )
}

export interface IInputLabel {
  label: string
  name: string
  required?: boolean
  htmlFor?: string
  children?: React.ReactNode
  position?: "left" | "top"
  hasNote?: boolean
  fullWidth?: boolean
}

export default InputLabel
