import * as React from "react"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip"

interface TextTooltipProps {
  title: string | React.ReactNode
  children?: React.ReactNode
}

const TextTooltip: React.FC<TextTooltipProps> = ({ title, children }: TextTooltipProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent>{title}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

TextTooltip.displayName = "TextTooltip"

export { TextTooltip }
