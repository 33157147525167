import React from "react"
import InputLabel, { IInputLabel } from "./InputLabel"
import TextInput, { ITextInput } from "./TextInput"

type ITextInputLabel = ITextInput &
  IInputLabel & {
    defaultValue?: string
  }

const TextInputLabel: React.FC<ITextInputLabel> = ({
  label,
  required,
  name,
  position,
  defaultValue,
  fullWidth,
  ...rest
}: ITextInputLabel) => {
  return (
    <InputLabel
      name={name}
      label={label}
      htmlFor={name}
      required={required ?? false}
      position={position}
      fullWidth={fullWidth}
    >
      <TextInput {...rest} name={name} required={required} defaultValue={defaultValue} />
    </InputLabel>
  )
}

export default TextInputLabel
