import React, { useRef } from "react"
import Skeleton, { SkeletonProps } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

interface ISkeletonFormControl extends SkeletonProps {
  loading: boolean
  children: React.ReactNode
}

const SkeletonFormControl: React.FC<ISkeletonFormControl> = ({ children, loading, ...rest }: ISkeletonFormControl) => {
  const loadingRef = useRef(null)
  const loadedRef = useRef(null)
  const nodeRef: any = loading ? loadingRef : loadedRef

  return (
    <>
      {loading && (
        <div ref={nodeRef}>
          <Skeleton {...rest} className="skeleton-form-control" />
        </div>
      )}
      {!loading && React.cloneElement(<>{children as any}</>, { ref: nodeRef })}
    </>
  )
}

export default SkeletonFormControl
