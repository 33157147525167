import React from "react"

export const MaterialButton: React.FC<any> = ({ children, color }: any) => {
  function createRipple(event: any) {
    const button = event.currentTarget

    const circle = document.createElement("span")
    const diameter = Math.max(button.clientWidth, button.clientHeight)
    const radius = diameter / 2

    circle.style.width = circle.style.height = `${diameter}px`
    const rect = button.getBoundingClientRect()

    if (event.clientX === 0 && event.clientY === 0) {
      circle.style.left = `${rect.width / 2 - radius}px`
      circle.style.top = `${rect.height / 2 - radius}px`
    } else {
      circle.style.left = `${event.clientX - rect.left - radius}px`
      circle.style.top = `${event.clientY - rect.top - radius}px`
    }
    circle.classList.add("ripple")
    if (color) {
      circle.classList.add(color)
    }

    setTimeout(() => {
      circle.remove()
    }, 400)

    button.appendChild(circle)
  }

  return React.Children.map(children, (child) =>
    React.cloneElement(child, {
      ...child.props,
      onClick: (e: any) => {
        createRipple(e)
        child.props?.onClick?.(e)
      },
      style: { ...child.props.style, position: "relative", overflow: "hidden" },
    }),
  )
}
