import { Button } from "@/components/ui/button"
import { useWhiteLabelStore } from "@/components/whitelabel-store"
import { cn } from "@/lib/utils"
import { useSetLoading } from "@/utils/useLoader"
import axios from "axios"
import React, { useEffect, useState } from "react"
import { useForm, useWatch } from "react-hook-form"
import { useQuery } from "react-query"
import { useHistory } from "react-router"
import ErrorComponent from "../../../components/error-component/ErrorComponent"
import PasswordInputLabel from "../../../components/form/PasswordInputLabel"
import TextInputLabel from "../../../components/form/TextInputLabel"
import PasswordStrengthMeter from "../../../components/password-strength-meter/PasswordStrengthMeter"
import { useAuthStore, useIsAuthenticated } from "../../../context/AuthContext"
import { ThruFormProvider } from "../../../context/ThruFormContext"
import { PasswordModel } from "../../../types/PasswordModel"
import { showToast } from "../../../utils/ErrorUtils"
import { resetPassword, setPassword, validateResetPswdLink, validateSetPswdLink } from "../../../utils/PasswordClient"
import { changePasswordErrorSchema } from "../../account/AccountClient"

const inputs = {
  USERNAME: "username",
  PASSWORD: "password",
  CONFIRM_PASSWORD: "confirmPassword",
}

interface ISetPassword {
  token: string
  isReset: boolean
}

const SetPassword: React.FC<ISetPassword> = ({ token, isReset }: ISetPassword) => {
  const isAuthenticated = useIsAuthenticated()
  const logout = useAuthStore((state) => state.logout)
  const [submitted, setSubmitted] = useState(false)
  const form = useForm()
  const history = useHistory()
  const setLoading = useSetLoading()

  const {
    data: setPswdResponseData,
    isLoading,
    error: unkownError,
  } = useQuery(["validate-pwd", token], () => (isReset ? validateResetPswdLink(token) : validateSetPswdLink(token)), {
    retry: false,
  })
  const error = unkownError as any

  useEffect(() => {
    if (isAuthenticated) {
      logout({})
    }
  }, [isAuthenticated])

  const onSubmit = async (data: any) => {
    if (!setPswdResponseData) {
      return
    }

    const pswdModel: PasswordModel = {
      username: setPswdResponseData.username,
      key: token,
      password: data[inputs.PASSWORD],
      confirmPassword: data[inputs.CONFIRM_PASSWORD],
    }

    const func = isReset ? resetPassword : setPassword

    setLoading(true)
    await func(pswdModel)
      .then((res: any) => {
        setSubmitted(true)
      })
      .catch((e: any) => {
        try {
          if (axios.isAxiosError(e)) {
            const result = changePasswordErrorSchema.safeParse(e.response?.data)

            if (!result.success) {
              throw e
            }

            const problemDetails = result.data

            if (problemDetails.errors.NewPassword) {
              form.setError(inputs.PASSWORD, { message: problemDetails.errors.NewPassword.join("\n") })
            }
            if (problemDetails.errors.NewPasswordHash) {
              form.setError(inputs.PASSWORD, { message: problemDetails.errors.NewPasswordHash.join("\n") })
            }
          }
        } catch (e) {
          showToast(`Failed to ${isReset ? "update" : "set"} password.`, e)
          console.error(e)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const newPassword: any = useWatch({
    control: form.control,
    name: inputs.PASSWORD,
  })

  const brand = useWhiteLabelStore((state) => state.brand)

  return (
    <div className="flex h-full w-full">
      <ThruFormProvider
        loading={false}
        customForm={form}
        onSubmit={onSubmit}
        className="pswd-reset-container mb-auto mt-auto flex"
      >
        <div
          className="thru-pswd-reset-Container rounded-lg border bg-card text-card-foreground shadow-sm"
          style={{ opacity: isLoading ? "0.0" : "1.0", transition: "opacity 250ms ease" }}
        >
          <div className={cn("thru-pswd-reset-header", submitted ? "hidden" : "grid")}>
            {/* from here will be hidden*/}
            <h1 className="my-6 text-center">{isReset ? "Reset Password" : "Set Password"}</h1>
          </div>
          {/* Below is part of the confirmation animation that will need to be visible (and the above part hidden) once the user successfully resets password */}
          <div className={cn("thru-pswd-reset-header", !submitted ? "hidden" : "grid")}>
            <h1 className="mt-4 text-center" style={{ marginBottom: "calc(50px + 1rem)" }}>
              Password Saved
            </h1>
            <svg className="circleLogo-outer checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx={26} cy={26} r={25} fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
          {/* *********************************************************************************************************************************************** */}
          {error && (
            <div className="thru-pswd-reset-body d-grid my-5 flex-grow place-items-center px-4">
              <ErrorComponent error={error} />
            </div>
          )}
          {!error && (
            <div className="thru-pswd-reset-body px-8" hidden={error ? true : undefined}>
              {!submitted && (
                <div>
                  {/*  from here will be hidden */}
                  <div className="col mb-4">
                    <TextInputLabel
                      label="Username"
                      name={inputs.USERNAME}
                      placeholder="username"
                      value={setPswdResponseData?.username}
                      readOnly
                    />
                  </div>
                  <div className="col mb-4">
                    <PasswordInputLabel
                      label="New Password"
                      name={inputs.PASSWORD}
                      maxLength={256}
                      minLength={8}
                      required
                      newPassword
                    />
                    <PasswordStrengthMeter password={newPassword} mfaEnabled={setPswdResponseData?.mfaEnabled} />
                  </div>
                  <div className="col mb-4">
                    <PasswordInputLabel
                      label="Confirm Password"
                      name={inputs.CONFIRM_PASSWORD}
                      maxLength={256}
                      minLength={8}
                      required
                      buttonIdPrefix="confirm"
                      validate={(value) => (newPassword === value ? true : "Password does not match.")}
                    />
                  </div>
                </div>
              )}
              {!submitted && (
                <div className="col mb-0">
                  <div className="row">
                    {/* from here will be hidden */}
                    <div className="col mt-4 flex">
                      <div className="mr-4">
                        <Button id="btnSetPswd">{isReset ? "Reset" : "Set"}</Button>
                      </div>
                      <div className="mr-4">
                        <Button id="btnCancel" variant="outline" type="reset" data-dismiss="modal">
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="text-center text-primary"
                style={{
                  fontSize: "2rem",
                  marginTop: "calc(50px - 1rem)",
                  marginBottom: "1.5rem",
                  lineHeight: 1,
                }}
                hidden={!submitted ? true : undefined}
              >
                You are all set!
              </div>
              {/* Below is part of the confirmation animation that will need to be visible (and the above part hidden) once the user successfully resets password */}
              {submitted && (
                <div className="flex justify-center mb-24">
                  <Button
                    id="btnLogin"
                    className="w-auto whitespace-nowrap"
                    onClick={() => {
                      history.push("/")
                    }}
                  >
                    Continue to Login
                  </Button>
                </div>
              )}
              {/* *********************************************************************************************************************************************** */}
            </div>
          )}
          <div
            className="Thru-portlet-footer border-t"
            style={{ display: "grid", placeItems: "center" }}
            hidden={error ? true : undefined}
          >
            {brand === "thru" && (
              <img className="img-fluid" src="\images\white-thru-logo.svg" alt="Thru logo" width="100px" />
            )}
            {brand === "boomi" && (
              <img className="img-fluid" src="\images\boomi-logo-hp.svg" alt="Thru logo" width="100px" />
            )}
          </div>
          {/* End of footer */}
        </div>
      </ThruFormProvider>
    </div>
  )
}

export default SetPassword
