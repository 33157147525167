import React, { useMemo } from "react"
import * as portals from "react-reverse-portal"

const ActionContext = React.createContext<{ portalNode: portals.HtmlPortalNode<any> } | undefined>(undefined)

type IActionProvider = {
  children: React.ReactNode
}

const ActionProvider: React.FC<IActionProvider> = ({ children }) => {
  const portalNode = useMemo(() => portals.createHtmlPortalNode(), [])

  return (
    <ActionContext.Provider
      value={{
        portalNode: portalNode,
      }}
    >
      {children}
    </ActionContext.Provider>
  )
}

function useActions() {
  const context = React.useContext(ActionContext)
  if (context === undefined) {
    throw new Error("useActions must be used within a ActionProvider")
  }
  return context
}

export { ActionProvider, useActions }
